
















































input[type=text]{
  background-color: #17234d;
  color: white;
}
.wrapper-input{
  border: 2px solid #084cae;
}
.unit {
  width: 25px;
  margin-left: 0;
  color: #c1daff;
  background-color: #0e142f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
