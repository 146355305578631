
























































$BiseauTopLeftBottomRightSmall: polygon(5% 0%, 80% 0%, 100% 0, 100% 80%, 95% 100%, 20% 100%, 0 100%, 0 15%);
$BiseauTopLefSmall: polygon(5% 0%, 80% 0%, 100% 0, 100% 100%, 95% 100%, 20% 100%, 0 100%, 0 15%);
$light-blue: #88eeff;

#episodes {
  .wrapper {
    padding: 10px calc(10% + 5px) 10px;

    > div {
      width: 100%;
    }
  }

  .episode {
    width: calc((100% / 3) - 20px);
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    display: flex;
    cursor: pointer;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .img-wrapper {
      clip-path: $BiseauTopLefSmall;
      background: white;
      z-index: 2;
    }

    img {
      height: calc(100% - 10px);
      width: calc(100% - 10px);
      top: 5px;
      left: 5px;
      object-fit: cover;
      padding: 0;
      clip-path: $BiseauTopLefSmall;
    }

    > div:nth-child(2) {
      background: rgb(11, 13, 56);
      border: $light-blue 2px solid;
      border-top: 0;
      padding: 10px;
      align-content: start;
      flex-grow: 1;

      * {
        z-index: 2;
        font-size: 1rem;
        margin-top: -5px;
        margin-bottom: auto;
      }

      .title {
        color: $light-blue;
        margin-bottom: 0;
        line-height: 18px;
      }
    }
  }


  @media screen and (min-width: 992px) {
    .wrapper {
      padding: 30px calc(10% + 11px) 10px;

      > div {
        padding-bottom: 1rem;
      }
    }
    .episode {
      left: 25px;
      min-height: calc((100% / 3) - 60px);
      width: 100%;
      flex-direction: row;

      &:not(:last-child) {
        margin-right: unset;
      }

      .img-wrapper {
        clip-path: $BiseauTopLeftBottomRightSmall;
      }

      img {
        clip-path: $BiseauTopLeftBottomRightSmall;
      }


      > div:nth-child(2) {
        background: $light-blue;
        clip-path: $BiseauTopLeftBottomRightSmall;
        width: 70%;
        height: 100%;
        left: -50px;
        margin-left: 0;
        align-content: center;
        padding: 10px 20px 10px 65px;

        * {
          font-size: 1.5rem;
        }

        .title {
          font-size: 2.3rem;
          line-height: 45px;
        }

        &:after {
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          position: absolute;
          clip-path: $BiseauTopLeftBottomRightSmall;
          top: 2px;
          left: 2px;
          background: rgb(11, 13, 56);
          content: "";
          z-index: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1780px) {
  #episodes .wrapper > div {
    width: 75%;
  }
}
