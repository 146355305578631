























































#web-interface .screen-subtitle {
  width: 100%;
}

@media screen and (min-width: 992px) {
  #web-interface .screen-subtitle {
    width: unset;
  }
}
