


























































#web-interface .main .wrapper.select-container .question-group {
  padding: 0;
}

#web-interface .main .wrapper.select-container .question-group .question {
  padding: 5px 20px;
}
.screen-subtitle{
  height: auto;
}
.select {
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #081434db;
  width: 100%;
  margin: 0 0 0 auto;
}

#web-interface .select-container .select button {
  background-color: #167ed0;
  width: 50px;
  position: absolute;
  right: 0;
  height: 100%;
  text-align: center;
  color: white;
  order: 2;
}

#web-interface .select-container .select button:after {
  content: "V";
  position: relative;
  display: block;
  transform: rotate(0deg);
  transition: ease-in-out 0.3s transform;

}

#web-interface .select-container .select.active button:after {
  transform: rotate(180deg);

}

.max-container-select {
  max-width: 100%;
}
.select{
  width: 325px;
}
.question{
  width: 250px;
}
@media screen and (min-width: 992px) {
  .max-container-select {
    max-width: 65%;
    min-width: 475px;
  }
  .select{
    min-width: 325px;
    width: unset;
  }
  .question{
    width: auto;
  }
}

select {
  order: 1;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 10px;
  margin: 0;
  color: white;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
}

option {
  padding-left: 5px;
  color: black;
}

.question-container {
  padding: 15px 0 15px 15px;
}

