













































#web-interface .wrapper {
  padding: 10px calc(10% + 11px) 10px;
}
label {
  color: #88eeff;
  font-family: 'Anton', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}
input {
  background-color: #468eff4a;
  color: #88eeff;
}
.error {
  color: #ff6767
}
a {
  color: #88eeff;
}

@media screen and (min-width: 992px) {
  #web-interface .wrapper {
    padding: 30px calc(10% + 11px) 10px;
  }
  .beveledbutton-wrapper {
    height: 40px;
  }
  #web-interface .main form {
    min-width: 250px;
  }
}


