










































































































$BiseauTopLeftSmall: polygon(5% 0%, 80% 0%, 100% 0, 100% 100%, 95% 100%, 20% 100%, 0 100%, 0 15%);

#web-interface .beveledbutton-wrapper {
  min-height: 60px;
  margin-bottom: 10px;
  width: calc(100% - 50px);

  &:before {
    display: none;
  }

  &:last-of-type {
    margin-bottom: 35px;
  }
}

#web-interface .beveledbutton-wrapper, #web-interface .beveledbutton-wrapper:before, #web-interface .beveledbutton-wrapper a {
  clip-path: $BiseauTopLeftSmall;
}

#web-interface .beveledbutton-wrapper.results a {
  color: #88eeff;
  text-transform: uppercase;
  display: flex;
  background-color: #1c2664;
  height: 54px;
  font-weight: 900;
  padding-left: 10px;
  top: 3px;
  clip-path: polygon(5% 0%, 80% 0%, 100% 0, 100% 100%, 95% 100%, 20% 100%, 0 100%, 0 15%);
  justify-content: space-between;
  padding-right: 0;
  text-decoration: none;
}

 .validation-wrapper {
  background-color: #101646;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  font-family: "Anton", sans-serif;
  color: #2eaade;
  font-weight: 900;
  font-size: 40px;
   margin: 0 10px 0 0;
}
#web-interface .beveledbutton-wrapper .validation-wrapper{
  margin: 0 0 0 auto;
  border-left: solid 2px #88eeff;
}
#web-interface .true .validation-wrapper,#web-interface .true.validation-wrapper{
  color: #6acbdc;
}

#web-interface .false .validation-wrapper, #web-interface .false.validation-wrapper {
  color: #fe0000;
}

#web-interface .beveledbutton-wrapper a.false {
  background-color: #630c0c;
  color: white;
}

#web-interface .screen-subtitle {
  width: 100%;
}
.help-wrapper{
  .border-bottom{
    border-bottom-color: #a8e3f6 !important; ;
  }
}
@media screen and (min-width: 992px) {
  #web-interface .beveledbutton-wrapper {
    width: 75%;
  }
  #web-interface .screen-subtitle {
    width: unset;
  }
}

.loader span {
  color: #a8e3f6;
  font-size: 62px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Anton", sans-serif !important;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

