



































































.character-mini {
  max-height: 250px;
  transform: scaleX(-1);
}
