










































.true-false-container .question-group input[type="radio"] + label {
  align-items: center;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  background: #88eeff;
  color: #88eeff;
  font-weight: 900;
  max-height: 50px;
}

.true-false-container .question-group input[type="radio"] + label::before {
   content: "";
   position: absolute;
   height: 100%;
   width: 80%;
 }

.true-false-container input[type="radio"]:first-of-type + label::before{
  position: absolute;
  width: calc(100% - 2px);
  left: 1px;
  top: 1px;
  height: calc(100% - 2px);
  background: #17234d;
  z-index: -1;
}
.true-false-container input[type="radio"]:last-of-type + label::before{
  position: absolute;
  width: calc(100% - 2px);
  left: 1px;
  top: 1px;
  height: calc(100% - 2px);
  background: #17234d;
  z-index: -1;
}
.true-false-container input[type="radio"]:last-of-type + label {
  clip-path: polygon(20% 0%, 80% 0%, 100% 0, 100% 80%, 86% 100%, 20% 100%, 0 100%, 0 0);
  z-index: 1;
}
.true-false-container input[type="radio"]:last-of-type + label::before {
   clip-path: polygon(20% 0%, 80% 0%, 100% 0, 100% 80%, 86% 100%, 20% 100%, 0 100%, 0 0);
 }
.true-false-container input[type="radio"]:first-of-type + label {
  clip-path: polygon(0 0, 100% 0, 100% 20%, 100% 100%, 80% 100%, 14% 100%, 0% 80%, 0% 20%);
  z-index: 1;
}
.true-false-container input[type="radio"]:first-of-type + label::before {
   clip-path: polygon(0 0, 100% 0, 100% 20%, 100% 100%, 80% 100%, 14% 100%, 0% 80%, 0% 20%);
}

.true-false-container input[type="radio"]:checked + label {
  background: white;
  color: white;
}
.true-false-container input[type="radio"]:checked + label::before {
   width: calc(100% - 6px);
   height: calc(100% - 6px);
   left: 3px;
   top: 3px;
   background: rgb(58, 212, 240);
   background: linear-gradient(180deg, rgba(58, 212, 240, 1) 0%, rgba(17, 98, 213, 1) 100%);
}

