






























































































.wrapper {
  padding: 10px calc(10% + 11px) 10px;
}

@media screen and (min-width: 992px) {
  .wrapper {
    padding: 30px calc(10% + 11px) 10px;
  }
}

input {
  text-align: center;
}

#web-interface .beveledbutton-wrapper.submit-btn:not(.disabled):hover button {
  left: 4px;
}

.loader span {
  color: #a8e3f6;
  font-size: 62px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Anton", sans-serif !important;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.feedback-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;

  &.starship {
    background-image: url("@/../vendors/images/bg/starship.jpg");
  }

  display: flex;
  flex-direction: row;


  .character-container {
    width: 40%;

    .nametag {
      position: absolute;
      bottom: 5vh;
      left: 5vw;
      background-color: #2d9bf3;
      border: 1vh solid black;
      text-transform: uppercase;
      color: white;
      font-family: 'Anton', sans-serif;
      font-size: 5vh;
      padding: 0 50px;
    }

    img {
      position: absolute;
      bottom: 0;
      left: 10vw;
      width: 25%;
    }
  }

  .speech-container {
    width: 60%;

    .speech-bubble-big {
      position: absolute;
      top: 8vh;
      right: 3vw;
      background-image: url("@/../vendors/images/bubble-text-big.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 50vw;
      height: calc(50vw * (688 / 964));

      padding: 7% 2%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-around;

      .dialogue-content {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          text-align: center;
          font-size: 1.5vw;
        }
      }

      .close-btn {
        position: absolute;
        top: 1.5vw;
        right: 1.5vw;
        width: 3.5vw;
        height: 3.5vw;

        background-color: #2d9bf3;
        color: white;
        text-align: center;

        font-size: 3vw;
        line-height: 3.2vw;

        cursor: pointer;
      }
        .dialogue-pagination {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: stretch;
      }

      .page-btn {
        margin: 5px;
        background-color: #2d9bf3;
        color: white;
        text-align: center;

        font-size: 2vw;

        cursor: pointer;
        padding: 0 15px;
        display: flex;
        align-items: center;
      }

    }
  }
}


