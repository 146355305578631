













.rotate-instruction {
  display: none;

  background-color: #000000ef;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  img {
    max-width: 33vw;
  }

  @media all and (orientation: portrait) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
