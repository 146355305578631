


































#videoContainer {
  width: 100vw;
  height: 100vh;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
}
