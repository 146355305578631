






























.feedback-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;

  &.starship {
    background-image: url("@/../vendors/images/bg/starship.jpg");
  }

  &.mars {
    background-image: url("@/../vendors/images/bg/mars.jpg");
  }

  &.lol2000 {
    background-image: url("@/../vendors/images/bg/lol2000.jpg");
  }

  display: flex;
  flex-direction: row;


  .character-container {
    width: 40%;

    .nametag {
      position: absolute;
      bottom: 5vh;
      left: 5vw;
      background-color: #2d9bf3;
      border: 1vh solid black;
      text-transform: uppercase;
      color: white;
      font-family: 'Anton', sans-serif;
      font-size: 5vh;
      padding: 0 50px;
    }

    img {
      position: absolute;
      bottom: 0;
      left: 10vw;
      width: 25%;
    }
  }

  .speech-container {
    width: 60%;

    .speech-bubble-big {
      position: absolute;
      top: 8vh;
      right: 3vw;
      background-image: url("@/../vendors/images/bubble-text-big.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 50vw;
      height: calc(50vw * (688 / 964));

      padding: 7% 2%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-around;

      .dialogue-content {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          text-align: center;
          font-size: 1.5vw;
        }
      }

      .close-btn {
        position: absolute;
        top: 1.5vw;
        right: 1.5vw;
        width: 3.5vw;
        height: 3.5vw;

        background-color: #2d9bf3;
        color: white;
        text-align: center;

        font-size: 3vw;
        line-height: 3.2vw;

        cursor: pointer;
      }

      .dialogue-pagination {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: stretch;
      }

      .page-btn {
        margin: 5px;
        background-color: #2d9bf3;
        color: white;
        text-align: center;

        font-size: 2vw;

        cursor: pointer;
        padding: 0 15px;
        display: flex;
        align-items: center;


        &.page-disabled {
          background-color: #8f8f8f;
          cursor: default;
        }

        &.retry-btn {
          font-size: 1.5vw;
        }
      }
    }
  }
}
