




















































































.character-mini {
  max-height: 250px;
  transform: scaleX(-1);
}

#advice-holder {
  .hideshowbutton {
    width: 45px;
    margin-left: auto;
    font-weight: 900;
    text-align: center;
    border-radius: 5px;
    color: #88eeff;
    font-size: 20px;
    position: absolute;
    bottom: 5px;
    background: radial-gradient(circle, #132b76 0%, #060911 95%);
    right: 90px;
    width: 45px;
    height: 45px;
    //height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    border: 1px solid #167ed0;

    span {
      margin-left: -10px;
      margin-top: 1px;
      animation: 1s ease-in-out alternate infinite slideleft;
      transition: ease-in-out all 0s;
      animation-delay: 1s;
      transform: rotate(0deg);
    }

    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  &.hidden .hideshowbutton span {
    //transform: rotate(180deg);
  }
}

@keyframes slideleft {
  from {
    margin-left: -10px;
  }
  to {
    margin-left: 10px;
  }
}
