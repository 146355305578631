//$primary: red;

@import "./bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');

$dark-background: #060911;
$light-blue: #88eeff;

$title-font: 'PT Sans', sans-serif;
$stylisefont: 'Russo One', sans-serif;
$angle: 10%;
$angle_2: 90%;
$angle_1_ratio_58: 20%;
$angle_2_ratio_58: 80%;
$title-font-size: 2rem;

$BiseauTopLeftBottomRightSmall: polygon(5% 0%, 80% 0%, 100% 0, 100% 80%, 95% 100%, 20% 100%, 0 100%, 0 15%);
$BiseauTopLeftBottomRight: polygon(15% 0%, 80% 0%, 100% 0, 100% 80%, 86% 100%, 20% 100%, 0 100%, 0 15%);
$BiseauBottomLeftBottomRight: polygon(100% 0, 100% 90%, 90% 100%, 10% 100%, 0 90%, 0 0);
$mainBiseau_ratio58: polygon(92% 0, 100% 17%, 100% 83%, 92% 100%, 8% 100%, 0 83%, 0 17%, 8% 0);
$mainBiseau: polygon(92.5% 0px, 100% 6%, 100.00% 94%, 92.5% 100%, 7.5% 100%, 0px 94%, 0px 6%, 7.5% 0px);
$mainBiseauMobile: polygon(95.5% 0px, 100% 12%, 100% 88%, 95.5% 100%, 5.5% 100%, 0px 88%, 0px 12%, 5.5% 0px);

.jumbotron {
  // should be relative path of the entry scss file
  background-size: cover;
}

body {
  position: relative;
  padding: 0;
  margin: 0;
  background-color: #000;
  overflow: hidden;
}

.content {
  position: relative;
}

.scalable-wrapper {
  position: relative;
  //height: 100vh;
  width: 100%;
  background-color: #000;
  overflow: hidden;
}

.app {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#web-interface {
  overflow: hidden;
  background-color: #060911;
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  .interface {
    overflow: hidden;
    display: flex;
    height: 100%;
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 90%;
      overflow: unset;
      height: 90%;
    }
  }

  .main {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;

    .template-wrapper {
      width: 100%;
      height: 100%;

      @include media-breakpoint-up(lg) {
        .inner-content {

          top: 40px;
        }
      }
    }

    .inner-content {
      height: 100%;
      width: 100%;
      border-bottom: 3px solid $light-blue;
      //clip-path: $mainBiseauMobile;
      padding: 15px 10px;
      background: rgb(2, 0, 36);
      background-image: linear-gradient(90deg, $dark-background 0%, rgba(51, 73, 152, 0.5) 50%, $dark-background),
      url('/vendors/images/background.svg');
      background-size: cover;

      &.sky-background {
        background-image: linear-gradient(90deg, rgba(51, 73, 152, 0) 0%, rgba(19, 41, 108, 0.85) 100%), url('/vendors/images/background-sky.jpg');
      }

      &#titlescreen {
        background-image: linear-gradient(90deg, rgba(51, 73, 152, 0) 0%, rgba(19, 41, 108, 0.55) 100%), url('/vendors/images/background-title.jpg');
      }

      &:not(.full) {
        padding: 15px 5% 35px;
        width: 100%;
        clip-path: unset;
        margin: auto;
      }

      @include media-breakpoint-up(lg) {
        border-bottom: none;
        clip-path: $mainBiseau;
        padding: unset;
        height: calc(100% - 82px);
        width: calc(100% - 82px);
        top: -30px;
        &.full {
          top: unset;
        }
        &:not(.full) {
          width: 80%;
          padding: unset;
        }
        @media (min-aspect-ratio: 8/5) {
          clip-path: $mainBiseau_ratio58;
        }
      }

      &.full {
        margin: unset;
        padding: 50px 10px 10px;

        @include media-breakpoint-up(lg) {
          top: -25px;
          padding: unset;
        }

        &.no-title {
          top: 0;
        }

        .wrapper {
          width: calc(100% + 78px);
          //padding:30px calc(10% + 11px) 10px;
          left: -39px;

          &.full {
            left: 0;
          }
        }
      }
    }

    .wrapper {
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      &:not(.question-template) {
        overflow-y: auto;
        overflow-x: hidden;
      }

      &.select-container .question-wrapper {
        flex-wrap: wrap;

        .img-container {
          margin-bottom: -20px;
        }

        img {
          max-height: 250px;
        }

        @include media-breakpoint-up(lg) {
          flex-wrap: nowrap;
          max-height: unset;
          img {
            max-height: unset;
          }
          .img-container {
            margin-bottom: unset;
          }
        }
      }

      .question-wrapper, .inner-wrapper {
        height: 100%;
        box-sizing: content-box;
        overflow: auto;
      }

      .question-wrapper img {
        width: 100%;
        height: 100%;
      }

      .question-container {
        width: 100%;
        margin-bottom: 45px;
        padding: 25px;
        height: 100%;

        @include media-breakpoint-up(lg) {
          margin-bottom: unset;
          padding: 15px 10px 15px 0;
          height: auto;
        }

        .scroll-question {
          height: 100%;
          justify-content: center;
          top: 0;
          transition: top ease-in 0.5s;
        }
      }

      .slide .question-container {
        max-height: calc(100% - 50px);
      }

      .question-group {
        margin-top: 0;
        margin-bottom: 10px;

        &:not(.button) {
          background-color: #2f5d8042;
          padding: 10px;
        }

        &.button:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      .question {
        font-size: 1rem;
        color: white;
      }

      .img-container {
        padding-right: 0.5rem;
        min-width: 30%;
      }

      @include media-breakpoint-up(lg) {
        overflow: unset;
        &.question-template {
          padding: 2vh 3vw 10px;

          img {
            padding-bottom: 10px;
            object-fit: contain;
            max-width: 100%;
          }
        }
        .question-wrapper {
          padding: 10px 10px 0 0;
          box-sizing: unset;
        }
        .slide .question-container {
          max-height: calc(100% - 100px);
        }
        .question:not(.button) {
          font-size: 1.2rem;
          //min-height: 50px;
          background-color: #2f5d8042;
        }
        .question-group {
          margin-bottom: 20px;

          &:not(.button) {
            background-color: #2f5d8042;
            padding: 15px 20px;
          }

          &.beveledbutton-wrapper {
            height: 40px;
            min-height: 40px;
          }

          .question {
            background-color: transparent;
            padding-right: 10px;
          }
        }
        .img-container {
          padding-right: 1rem;
        }
      }
    }

    .screen-title {
      z-index: 2;
      color: $light-blue;
      font-family: $stylisefont;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 0.5;
      padding: 10px;
      white-space: nowrap;
      top: 10px;
      width: 40%;
      clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
      margin: 0 auto 0;
      border-bottom: 5px solid $light-blue;
      @include media-breakpoint-up(lg) {
        font-size: $title-font-size;
        background-color: $dark-background;
        padding: unset;
        position: relative;
        top: -30px;
        line-height: normal;
      }
    }

    .screen-subtitle {
      padding: 10px;
      color: $light-blue;
      border: 1px solid $light-blue;
      font-weight: 900;
      font-size: 1.3rem;
      background-color: #2f5d8042;
      width: 100%;
      text-align: center;

      &.foldable-question {
        max-height: 150px;
        transition: ease-in-out 0.5s max-height;

        &.unfolded {
          max-height: 400px;
          transition: ease-in-out 0.5s max-height;

          p {
            transition: ease-in-out 0.5s max-height;
            max-height: 400px;
          }

          .fold-button {
            transform: rotate(180deg);
            transition: ease-in-out 0.5s transform;
          }
        }

        p {
          transition: ease-in-out 0.5s max-height;
          max-height: 100px;
          overflow: hidden;
          margin-bottom: 5px
        }

        .fold-button {
          height: 15px;
          transform: rotate(0deg);
          transition: ease-in-out 0.5s transform;
          display: flex;
          justify-content: center;
          margin: auto;

          span {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: $light-blue transparent transparent transparent;
          }
        }
      }

      &:not(.question-template) {
        background-color: #468eff4a;
      }

      @include media-breakpoint-up(lg) {
        &.foldable-question {
          max-height: unset;
          overflow: unset;

          p {
            max-height: unset;
            overflow: unset;
          }

          .fold-button {
            display: none;
          }
        }
        padding: 1rem 2rem 1rem;
        font-size: 1.5rem;
      }
    }

    .question-wrapper .screen-subtitle {
      padding: 0.5rem;
      @include media-breakpoint-up(lg) {
        margin-top: 10px;
      }
    }

    .full .screen-subtitle {
      border: none;
      color: white;
      text-transform: uppercase;
    }

    .matching-question-container .slide > div {
      height: calc(100% - 100px);
      overflow: hidden;
    }

    input[type=text], input[type=password], input[type=email], select {
      padding: 0.5rem 0.7rem;
      margin: auto;
    }
  }

  #avatar {
    .avatar-wrapper {
      padding: 10px;
      margin: auto;
      background-size: cover;
      height: 50vh;
      width: 15vw;
      @include media-breakpoint-up(lg) {
        margin: 10px 25px;
      }

      img {
        height: 100%;
      }

      &:before, &:after {
        content: "";
        background-size: 100%;
        display: block;
        z-index: -1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
      }

      &:before {
        background: rgb(58, 212, 240);
        background: radial-gradient(circle, rgba(28, 159, 255, 0.33) 0%, #0f438600 55%);
      }

      &:after {
        background-image: url('/vendors/images/background-avatar.svg');
      }
    }
  }

  #avatar, #titlescreen {
    .beveledbutton-wrapper {
      width: auto;
      height: 50px;
      min-width: 210px;
      font-size: 1.1rem;
      white-space: nowrap;
      @include media-breakpoint-up(lg) {
        min-width: calc(100% / 2.5);
        font-size: 1.5rem;
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-row-gap: 4px;
    overflow: hidden;
    justify-content: space-between;
    align-content: center;
    grid-template-rows: repeat(3, 25%);
    height: 100%;
    @include media-breakpoint-up(lg) {
      grid-template-rows: repeat(auto-fit, 15%);
      grid-template-columns: repeat(3, 30%);
      grid-row-gap: 4%;
      overflow: unset;
    }

    .item.beveledbutton-wrapper {
      height: calc(100% - 8px);
      top: 0;
      left: -5px;
      width: calc(100% - 10px);
      background-color: #167ed0;

      &:hover {
        &:after {
          display: none;
        }

        button {
          text-shadow: none;
        }
      }

      button {
        text-transform: capitalize;
        font-family: $title-font;
        color: $light-blue;
        padding: 0 15px;
        @include media-breakpoint-up(lg) {
          font-size: 1.4rem;
          padding: unset;
        }
      }

      &.active {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        background-color: transparent;

        &:before {
          background-color: transparent;
        }

        .character-wrapper {
          display: block;
          width: 125px;
          background: white;
          position: relative;
          z-index: 2;
          clip-path: $BiseauTopLeftBottomRight;

          &:after {
            position: absolute;
            content: "";
            background-color: #f6c441;
            display: block;
            z-index: 1;
            clip-path: $BiseauTopLeftBottomRight;
          }

          img {
            z-index: 5;
            clip-path: $BiseauTopLeftBottomRight;
            top: 4px;
            object-fit: cover;
            left: 4px;
            height: calc(100% - 8px);
            width: calc(100% - 8px);
            background-color: #f6c441;
          }
        }

        button {
          background-color: white;
          height: calc(100% - 8px);
          left: -10px;
          width: 100%;
          z-index: 1;
          color: white;

          @include media-breakpoint-up(lg) {
            left: -15px;
            width: calc(100% + 15px);
          }

          &:before {
            content: "";
            background: rgb(58, 212, 240);
            background: linear-gradient(180deg, rgba(58, 212, 240, 1) 0%, rgba(17, 98, 213, 1) 100%);
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            top: 4px;
            left: 4px;
            display: block;
            z-index: -1;
            position: absolute;
            clip-path: $BiseauTopLeftBottomRightSmall;
          }
        }
      }

      &.error {
        clip-path: none;
        background-color: transparent;
        overflow: visible;

        &:before {
          display: none;
        }

        button {
          top: 0;
          background-color: #ff494b;
          margin: 0;
          height: 100%;
          width: 100%;
          color: white;
          z-index: 2;
        }

        &:after {
          position: absolute;
          content: "";
          left: 10%;
          top: 10%;
          z-index: 0;
          width: 80%;
          height: 65%;
          background-color: #ff494bbf;
          box-shadow: #ff494bbf 0px 20px 19px 2px;
          opacity: 1;
        }

        &:hover {
          background-color: transparent;

          &:after {
            display: block;
            background: #ff494bbf;
          }
        }
      }
    }
  }


  .beveledbutton-wrapper {
    clip-path: $BiseauTopLeftBottomRightSmall;
    display: block;
    position: relative;
    margin: auto;
    background: $light-blue;
    min-height: 35px;


    button, &.results .beveled-button {
      position: relative;
      color: $light-blue;
      background-color: #1c2664;
      transition: all ease-in-out 0.5s;
      width: calc(100% - 8px);
      left: 4px;
      top: 4px;
      height: calc(100% - 8px);
      z-index: 2;
      clip-path: $BiseauTopLeftBottomRightSmall;
    }

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 1px);
      top: 1px;
      left: 1px;
      height: calc(100% + 1px);
      background-color: #167ed0;
      clip-path: $BiseauTopLeftBottomRightSmall;
      transition: all 0.5s ease-in-out;
      z-index: 0;
    }

    &:after {
      content: "";
      background-color: transparent;
      opacity: 0;
      position: absolute;
      display: block;
      z-index: 1;
      left: calc(5% + 1px);
      top: 0%;
      width: 1px;
      transition: all ease-in-out 0.5s;
      height: 2px;
    }

    &.cancel-button {
      background-color: #cb6868;
      max-height: 30px;

      &:before {
        display: none;
      }

      button {
        background-color: #641c1c;
        color: #cb8181;
        font-size: 15px;
      }

      @include media-breakpoint-up(lg) {
        max-height: 50px;
        button {
          font-size: 1.5rem;
        }
      }
    }

    &.submit-btn {

      &:not(.disabled):hover:after {
        opacity: 0.8;
        background: rgb(136, 238, 255);
        background: linear-gradient(180deg, rgba(136, 238, 255, 1) 25%, rgba(136, 238, 255, 1) 50%, rgba(136, 238, 255, 0) 75%);
        animation: 2.5s linear 0s infinite turn;
      }

      &.cancel-button:hover:after {
        background: #e39f9f;
        background: linear-gradient(180deg, rgb(255, 151, 151) 25%, rgb(253, 112, 112) 50%, rgba(255, 146, 136, 0) 75%);
      }

      &:not(.disabled) {
        &.cancel-button:hover button {
          text-shadow: 0 0 3px #ffe9e9;
        }

        &:hover button {
          text-shadow: 0 0 3px $light-blue;
          color: white;
        }
      }

      &.disabled {
        opacity: 0.95;
        background-color: #2f3f87;

        &:before {
          background-color: #2f3f87;
        }

        button {
          color: #2f3f87;
          background-color: #0e142e;
        }
      }
    }

    @keyframes turn {
      0% {
        top: 0%;
        width: 1px;
        height: 3px;
      }
      20% {
        width: 50%;
        height: 3px;
        left: 50%;
        top: 0;
      }
      45% {
        top: 0;
        width: 5%;
        left: 95%;
        height: 80%;
      }
      55% {
        top: 25%;
        left: 85%;
        width: 15%;
        height: 100%;
      }
      70% {
        left: 0%;
        top: 85%;
        width: 60%;
        height: 85%
      }
      85% {
        top: 5%;
        height: 95%;
        left: 0;
        width: 2px;
      }
      100% {
        top: 0;
        height: 5%;
        left: 0;
        width: 5%;
      }
    }
  }

  .decoration-main {
    height: 5%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: end;
    position: absolute;
    bottom: 0;

    span {
      display: flex;
      justify-content: center;
    }

    > span:nth-child(1) {
      width: 85%;
      margin: 0 auto;
      height: 65%;
      position: relative;

      > span:nth-child(1), > span:nth-child(3) {
        height: 100%;
        width: 50%;
        transform: skew(-30deg);

        @include media-breakpoint-up(lg) {
          background-color: $dark-background;
        }

        > span {
          height: 100%;
          width: calc(100% / 4);
          margin-right: 4px;
        }

        > span:nth-child(1) {
          background-color: #0e66b0;
          box-shadow: inset 0px 1px 7px 4px rgba(150, 229, 255, 0);
          animation: glow 4s infinite;
          animation-delay: 0;

        }

        @keyframes glow {
          0% {
            box-shadow: inset 0px 1px 7px 4px rgba(150, 229, 255, 0)
          }
          20% {
            box-shadow: inset 0px 1px 7px 4px rgba(61, 164, 255, 0.71)
          }
        }

        > span:nth-child(2) {
          background-color: #1069b1;
          box-shadow: inset 0px 1px 7px 4px rgba(150, 229, 255, 0);
          animation: glow 4s infinite;
          animation-delay: 1s;
        }

        > span:nth-child(3) {
          background-color: #2eaadf;
          box-shadow: inset 0px 1px 7px 4px rgba(150, 229, 255, 0);
          animation: glow2 4s infinite;
          animation-delay: 2s;
        }

        > span:nth-child(4) {
          background-color: $light-blue;
          margin-right: -15px;
          box-shadow: inset 0px 1px 7px 4px rgba(150, 229, 255, 0);
          animation: glow2 4s infinite;
          animation-delay: 3s;
        }

        @keyframes glow2 {
          0% {
            box-shadow: inset 0px 1px 7px 4px rgba(150, 229, 255, 0)
          }
          20% {
            box-shadow: inset 0px 1px 7px 4px rgba(181, 235, 255, 0.63)
          }
        }
      }

      > span:nth-child(2) {
        background: $light-blue;
        width: 26px;
      }

      > span:nth-child(3) {
        transform: rotate(180deg) skew(30deg);
      }

    }

    > span:nth-child(3) {
      height: 15%;
      margin-top: auto;
      width: 100%;

      @include media-breakpoint-up(lg) {
        background-color: $dark-background;
      }

      span {
        background-color: $light-blue;
        width: 49%;
        height: 100%;

        &:nth-child(2) {
          margin-left: -10px;
        }
      }
    }
  }

  .border-container {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .border-left, .border-right {
    -webkit-clip-path: polygon(0 $angle, 100% 0, 100% 100%, 0 $angle_2);
    clip-path: polygon(0 $angle, 100% 0, 100% 100%, 0 $angle_2);
    background-color: #167ed0;
    height: 100%;
    width: 10%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    @media (min-aspect-ratio: 8/5) {
      -webkit-clip-path: polygon(0 $angle_1_ratio_58, 100% 0, 100% 100%, 0 $angle_2_ratio_58);
      clip-path: polygon(0 $angle_1_ratio_58, 100% 0, 100% 100%, 0 $angle_2_ratio_58);
    }

    span {
      -webkit-clip-path: polygon(10px $angle, 100% 10px, 100% calc(100% - 10px), 10px $angle_2);
      clip-path: polygon(10px $angle, 100% 10px, 100% calc(100% - 10px), 10px $angle_2);
      background-color: $dark-background;
      position: absolute;
      height: 100%;
      width: 100%;
      @media (min-aspect-ratio: 8/5) {
        -webkit-clip-path: polygon(10px $angle_1_ratio_58, 100% 10px, 100% calc(100% - 10px), 10px $angle_2_ratio_58);
        clip-path: polygon(10px $angle_1_ratio_58, 100% 10px, 100% calc(100% - 10px), 10px $angle_2_ratio_58);
      }
    }

    span:nth-child(2) {
      background-color: #167ed0;
      -webkit-clip-path: polygon(18px $angle, 100% 18px, 100% calc(100% - 18px), 18px $angle_2);
      clip-path: polygon(18px $angle, 100% 18px, 100% calc(100% - 18px), 18px $angle_2);
      @media (min-aspect-ratio: 8/5) {
        -webkit-clip-path: polygon(18px $angle_1_ratio_58, 100% 18px, 100% calc(100% - 18px), 18px $angle_2_ratio_58);
        clip-path: polygon(18px $angle_1_ratio_58, 100% 18px, 100% calc(100% - 18px), 18px $angle_2_ratio_58);
      }
    }

    span:nth-child(3) {
      background-color: $dark-background;
      -webkit-clip-path: polygon(21px $angle, 100% 21px, 100% calc(100% - 21px), 21px $angle_2);
      clip-path: polygon(21px $angle, 100% 21px, 100% calc(100% - 21px), 21px $angle_2);
      @media (min-aspect-ratio: 8/5) {
        -webkit-clip-path: polygon(21px $angle_1_ratio_58, 100% 21px, 100% calc(100% - 21px), 21px $angle_2_ratio_58);
        clip-path: polygon(21px $angle_1_ratio_58, 100% 21px, 100% calc(100% - 21px), 21px $angle_2_ratio_58);
      }
    }

    span:nth-child(4) {
      background-color: #167ed0;
      -webkit-clip-path: polygon(24px $angle, 100% 24px, 100% calc(100% - 24px), 24px $angle_2);
      clip-path: polygon(25px $angle, 100% 25px, 100% calc(100% - 25px), 25px $angle_2);
      @media (min-aspect-ratio: 8/5) {
        -webkit-clip-path: polygon(24px $angle_1_ratio_58, 100% 24px, 100% calc(100% - 24px), 24px $angle_2_ratio_58);
        clip-path: polygon(25px $angle_1_ratio_58, 100% 25px, 100% calc(100% - 25px), 25px $angle_2_ratio_58);
      }
    }

    span:nth-child(5) {
      background-color: $dark-background;
      -webkit-clip-path: polygon(25px $angle, 100% 25px, 100% calc(100% - 25px), 25px $angle_2);
      clip-path: polygon(27px $angle, 100% 27px, 100% calc(100% - 27px), 27px $angle_2);
      @media (min-aspect-ratio: 8/5) {
        -webkit-clip-path: polygon(25px $angle_1_ratio_58, 100% 25px, 100% calc(100% - 25px), 25px $angle_2_ratio_58);
        clip-path: polygon(27px $angle_1_ratio_58, 100% 27px, 100% calc(100% - 27px), 27px $angle_2_ratio_58);
      }
    }

    span:nth-child(6) {
      background-color: #1d2d84;
      -webkit-clip-path: polygon(34px $angle, 100% 34px, 100% calc(100% - 34px), 34px $angle_2);
      clip-path: polygon(34px $angle, 100% 34px, 100% calc(100% - 34px), 34px $angle_2);
      @media (min-aspect-ratio: 8/5) {
        -webkit-clip-path: polygon(34px $angle_1_ratio_58, 100% 34px, 100% calc(100% - 34px), 34px $angle_2_ratio_58);
        clip-path: polygon(34px $angle_1_ratio_58, 100% 34px, 100% calc(100% - 34px), 34px $angle_2_ratio_58);
      }
    }

    span:nth-child(7) {
      background-color: $dark-background;
      -webkit-clip-path: polygon(38px $angle, 100% 38px, 100% calc(100% - 38px), 38px $angle_2);
      clip-path: polygon(38px $angle, 100% 38px, 100% calc(100% - 38px), 38px $angle_2);
      @media (min-aspect-ratio: 8/5) {
        -webkit-clip-path: polygon(38px $angle_1_ratio_58, 100% 38px, 100% calc(100% - 38px), 38px $angle_2_ratio_58);
        clip-path: polygon(38px $angle_1_ratio_58, 100% 38px, 100% calc(100% - 38px), 38px $angle_2_ratio_58);
      }
    }

  }

  .border-right {
    transform: rotate(180deg);
  }

  .border-main {
    width: 80%;
    position: relative;

    span {
      width: 100%;
      display: block;
      position: absolute;
    }

    span:nth-child(1), span:nth-child(2) {
      background-color: #167ed0;
      height: 10px;
      top: 0;
    }

    span:nth-child(2) {
      top: unset;
      bottom: 0;
    }

    span:nth-child(3), span:nth-child(4) {
      height: 3px;
      top: 18px;
      background-color: #167ed0;
    }

    span:nth-child(4) {
      top: unset;
      bottom: 18px;
    }

    span:nth-child(5), span:nth-child(6) {
      background-color: #167ed0;
      height: 2px;
      top: 25px;
    }

    span:nth-child(6) {
      top: unset;
      bottom: 25px;
    }

    span:nth-child(7), span:nth-child(8) {
      background-color: #1d2d84;
      height: 2px;
      top: 35px;
    }

    span:nth-child(8) {
      top: unset;
      bottom: 35px;
    }
  }

  #advice-holder {
    position: absolute;
    z-index: 2;
    right: 15px;
    bottom: 0;
    font-family: $title-font;
    width: 250px;
    transition: right ease-in-out 1s;

    &.hidden {
      width: 90px;
      right: -85px;
      transition: right ease-in-out 1s;

      .bubble-text {
        opacity: 0;
        display: none;
        @include media-breakpoint-up(lg) {
          opacity: 1;
          display: block;
          z-index: 1;
        }
      }
    }

    .bubble-button {
      display: none;
      font-size: 5rem;
      color: white;
      right: 65px;
      position: absolute;
      bottom: 200px;
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: center;
        align-content: center;
      }

      &.hidden {
        display: none;
      }

      @include media-breakpoint-up(xl) {
        display: none;
      }
    }

    .bubble-text {
      text-align: center;
      height: 75px;
      background: white;
      border: black 2px solid;
      width: 170px;
      margin: auto 0 0 auto;
      transition: ease-in-out 0.5s opacity;

      &.hidden {
        opacity: 0;
        display: none;
        transition: ease-in-out 0.5s opacity;
        @include media-breakpoint-up(xl) {
          opacity: 1;
          display: block;
        }
      }

      span {
        width: 25px;
        background: lightgrey;
        position: absolute;
        right: 0;
        top: 0;
        @include media-breakpoint-up(xl) {
          display: none;
        }
      }

      &:before {
        content: "";
        position: absolute;
        display: block;
        z-index: 2;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 50px solid white;
        bottom: -15px;
        transform: rotate(-30deg);
        right: 75px;
      }
    }

    p, a {
      padding: 5px 2px;
      margin-bottom: 0;
      z-index: 3;
    }

    img {
      height: 125px;
      object-fit: unset;
      margin: auto 0 auto auto;
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &.hidden {
      @include media-breakpoint-up(lg) {
        width: 250px;
        right: 2.5vw;
      }

    }

    @include media-breakpoint-up(lg) {
      width: 250px;
      right: 2.5vw;
      img {
        height: 200px;
        object-fit: contain;
      }
      .bubble-text {
        padding: 20px 15px;
        height: 115px;
        width: 80%;
        border: black 3px solid;
        margin-bottom: 15px;
      }
    }
  }

  #button_down {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-25%);
    display: flex;
    background-color: #1d35665c;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
  }

  * {
    position: relative;
    font-family: $title-font;
    object-fit: contain;

    input {
      border: none;
    }
  }

  .spinner-border {
    border-color: #167ed0;
    border-right-color: transparent;

    span {
      color: transparent;
    }
  }

  .stylise-font {
    font-family: $stylisefont;
  }

}

button {
  border: none;
  position: relative;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  display: block;

  &.question {
    font-weight: unset;
    text-transform: unset;

    @include media-breakpoint-up(lg) {
      margin: 0 2rem 20px;
    }
  }

  &.button {
    background-color: rgba(50, 138, 197, 0.76);
    padding: 10px;
    color: white;

    &:hover {
      background-color: #2d9bf3;
    }
  }

  &.round {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    width: 45px;
    height: 45px;
    border-radius: 50px;
    background-color: #286c9d;
    color: $light-blue;
    border: solid #293667;
    box-shadow: 1px 1px 6px 0 #286c9d;
    overflow: hidden;

    &:before {
      border-radius: 50px;
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      box-shadow: 0 0 0 1px #1973b7 inset;
    }

    .innerborder {
      position: absolute !important;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 3px;
      left: 3px;
      padding: 7px;
      border-radius: 50px;
      background: rgb(17, 26, 69);
      background: radial-gradient(circle, #132b76 0%, $dark-background 95%);
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      z-index: 2;
    }

    @include media-breakpoint-up(lg) {
      width: 70px;
      height: 70px;
      &:before {
        box-shadow: 0 0 0 3px #1973b7 inset;
      }
      .innerborder {
        padding: 15px;
      }
    }

    .circle {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 40px;
      background-color: transparent;
      clip-path: polygon(100% 35%, 100% 60%, 0% 60%, 0% 35%);
      height: 100%;
      opacity: 0;
    }

    &:hover {
      text-shadow: 0 0 3px $light-blue;
    }

    &:hover .circle {
      background-color: $light-blue;
      animation: rotator linear .8s infinite;
      transition: opacity 0.5s ease-in-out;
      opacity: 1;
    }

    @keyframes rotator {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }


  &#close_button, &#info_button {
    position: fixed;
    height: 25px;
    width: 25px;
    top: 5px;
    right: 5px;
    z-index: 5;
    font-size: 1.3rem;
    font-weight: 900;
    @include media-breakpoint-up(lg) {
      height: 50px;
      width: 50px;
      font-size: $title-font-size;
      top: 15px;
      right: 15px;
    }
  }

  &#info_button {
    display: block;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &.arrow {
    transition: opacity ease-in 0.3s;
    background-color: transparent;

    &.small {
      height: 20px;
      margin: 0 auto 5px;

      svg {
        height: 15px;
        fill: #2b71a9;
      }

      &:hover svg {
        fill: $light-blue;
      }

      @include media-breakpoint-up(lg) {
        margin: 10px auto 10px;
        height: 30px;
        width: 50px;
      }
    }

    svg {
      fill: $light-blue;
      height: 80%;

    }

    &.inactive {
      //opacity: 0.2;
      pointer-events: none !important;
    }

  }

  &.down svg {
    transform: rotate(-90deg);
  }

  &.right svg {
    transform: rotate(180deg);
    margin-left: 2px;
    margin-right: -2px;

  }

  &.up svg {
    transform: rotate(90deg);
  }
}

.light-blue-text {
  color: $light-blue;
}

.help-wrapper {
  span {
    font-size: 20px;
    margin-bottom: 10px;
  }

  li {
    margin-bottom: 5px;
    color: $light-blue;

    p {
      color: white;
    }
  }
}

html {
  scrollbar-color: #0f518b rgba(22, 126, 208, 0.11);
}

.isotope {
  position: relative;
  display: inline-block;
  margin: 0 .1em 0 .2em;
  vertical-align: middle;
  text-align: center;

  .num, .mass {
    display: block;
    margin: -0.5ex auto;
    font-size: 60%;
  }
}
