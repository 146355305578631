


























































.drop-zone {
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}

#web-interface .main .wrapper .question-group:not(.button, .unmovable){
    background-color: #0f518b;
    border: solid #167ed0 1px;
    box-shadow: 2px 2px 7px 4px #0a1233b5;
}
#web-interface .main .wrapper .question-group.unmovable{
        background-color: #0b1026;
}

