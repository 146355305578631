











































$light-blue: #88eeff;

#web-interface .check-box-container {
  .input-wrapper {
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    height: 30px;
    width: 30px;
    opacity: 0;
    z-index: 2;
  }

  input[type="checkbox"]:checked + label:after {
    content: "X";
    position: absolute;
    top: 0;
    bottom: unset;
    left: 0;
    width: 100%;
    color: $light-blue;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 800;
  }

  input + label {
    height: 30px;
    position: absolute;
    background: #17234d;
    border: 1px solid $light-blue;
    width: 30px;
    z-index: 0;
    font-size: 30px;
    overflow: hidden;
    line-height: 1;
  }
}
