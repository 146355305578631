































































#web-interface .wrapper {
  padding: 10px calc(10% + 11px) 10px;
}

@media screen and (min-width: 992px) {
  #web-interface .wrapper {
    padding: 30px calc(10% + 11px) 10px;
  }
  .beveledbutton-wrapper {
    height: 40px;
  }
  #web-interface .main form {
    min-width: 250px;
  }
  label {
    color: #88eeff;
    font-family: 'Anton', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
  }
  input, select {
    background-color: #468eff4a;
    color: #88eeff;
  }
  select option {
    background-color: rgba(21, 69, 145, 1);
    color: #88eeff;
  }
}

a {
  color: #88eeff;
}
