



















































#web-interface #advice-holder.hidden .bubble-text {
  opacity: 0;
  display: none;
}

#web-interface #advice-holder .bubble-text {
  height: auto;
  margin: auto 0 10px auto;
  padding: 15px 10px;
  left: -15px;

  &:before {
    right: 55px;
  }
}

#web-interface #advice-holder .bubble-info {
  border: solid 2px #88eeff;
  display: none;
  box-shadow: 0 2px 10px #88eeff;
  border-radius: 15px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
  justify-content: center;
  align-items: center;
  background-color: #1a3474c2;

  p {
    color: #88eeff;
    font-size: 25px;
  }
}

#web-interface #advice-holder.hidden .bubble-info {
  display: flex;
}

#web-interface #advice-holder {
  right: 10px;

  img {
    display: block;
  }
}

#web-interface #titlescreen .beveledbutton-wrapper.cancel-button {
  max-width: 60%;
  white-space: nowrap;
}

@media screen and (min-width: 992px) {
  #web-interface #advice-holder.hidden, #web-interface #advice-holder {
    right: 15px;
  }
  #web-interface #advice-holder.bubble-info, #web-interface #advice-holder.hidden .bubble-info {
    margin: 5px 55px 10px auto;
  }
  #web-interface #advice-holder .bubble-text, #web-interface #advice-holder.hidden .bubble-text {
    margin: 0 0 15px 0;
    opacity: 1;
    padding: 20px 15px;
  }
  #web-interface #titlescreen .beveledbutton-wrapper.cancel-button {
    width: unset;
    height: 50px;
  }
}

@media screen and (min-width: 1140px) {
  #web-interface #advice-holder .bubble-info, #web-interface #advice-holder.hidden .bubble-info {
    display: none;
  }

  #web-interface #advice-holder .bubble-text, #web-interface #advice-holder.hidden .bubble-text {
    display: block;

    span {
      display: none;
    }
  }

  #web-interface #advice-holder.hidden {
    right: 15px;
    width: 250px;
  }
  .bubble-text-title {
    display: block;
    height: 130px !important;
    width: 100% !important;
  }
}

#web-interface #titlescreen.full {
  padding: 25px 10px 10px;

  .wrapper {
    padding: 10px 0;
  }

  .logo {
    max-height: 20px;
  }

  .title {
    max-height: 100px;
  }

  .button-wrapper {
    width: 85%;
  }

  .screen-subtitle {
    background: transparent !important;
    text-shadow: 0 0 4px white;
    margin: 0 auto auto;
    padding-top: 0.5rem;
    width: 60%;
  }

  img {
    padding-bottom: 0;
  }

  @media screen and (min-width: 992px) {
    padding: inherit;
    .wrapper {
      padding: 30px calc(10% + 11px) 10px;
    }
    .button-wrapper {
      width: 75%;
    }
    .logo {
      max-height: 40px;
    }

    .title {
      height: 30vh;
      max-height: 150px;
    }
    .bubble-text-title {
      height: 130px;
      width: 100%;
    }
    .screen-subtitle {
      width: 100%;
    }
  }
}
