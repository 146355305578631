











































































































































#game-container {
  background: url("@/../vendors/images/ciel-espace.jpg") center center no-repeat;
  background-size: cover;
}

.screen-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
